import firebase from 'gatsby-plugin-firebase';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '@context/GlobalContext';
import AvatarAudio from '../AvatarAudio/AvatarAudio';
import AvatarAudioButton from '../AvatarAudio/AvatarAudioButton';
import ResponsiveSharpImg from '@atoms/ResponsiveSharpImg';
import { ImageRenderType } from '@atoms/ResponsiveSharpImg/ResponsiveSharpImg';

import './_Avatar.scss';

Avatar.defaultProps = {
    size: 'l',
};

export default function Avatar(props) {
    const myAvatarWrapper = useRef(null);
    const myAudioTag = useRef(null);
    const [buttonState, setButtonState] = useState(false);
    const [gbContext, setGbContext] = useContext(GlobalContext);

    let mod_class = [
        'Avatar',
        props.className ? props.className : '',
        props.size !== undefined ? 'size-' + props.size : '',
        props.align !== undefined ? 'align-' + props.align : '',
        props.text ? 'text-only' : '',
    ];
    mod_class = mod_class.join(' ');

    useEffect(() => {
        if (gbContext.audioState) {
            // console.log("add class!!");
            // myAvatarWrapper.classList.add("my_style");
        }
    }, [gbContext.audioState]);

    function actionOnPlay() {
        console.log('audioObj ', gbContext.audioObj);
        console.log('audioObj ', gbContext.avatarAudioWrapper);

        if (gbContext.audioObj) {
            console.log('RESET STATE!');
            gbContext.audioObj.pause();
            gbContext.audioObj.currentTime = 0;
            gbContext.avatarAudioWrapper.className = 'Avatar__wrapper';
            setButtonState(false);
        }

        if (myAudioTag.current) {
            setButtonState(true);
            myAudioTag.current.play();
            myAvatarWrapper.current.className = 'Avatar__wrapper is-playing';

            console.log('audioObj ', gbContext.audioObj);

            setGbContext((prevState) => ({
                ...prevState,
                audioState: true,
                audioObj: myAudioTag.current,
                avatarAudioWrapper: myAvatarWrapper.current,
            }));
        }
    }

    function actionOnEnd() {
        if (myAudioTag.current) {
            setButtonState(false);
            myAudioTag.current.pause();
            myAudioTag.current.currentTime = 0;
            myAvatarWrapper.current.className = 'Avatar__wrapper';

            setGbContext((prevState) => ({
                ...prevState,
                audioState: false,
                audioObj: null,
                avatarAudioWrapper: null,
            }));
        }
    }

    function handleAudioButtonChange(state) {
        if (!buttonState) {
            console.log('play');
            actionOnPlay();

            if (firebase) {
                firebase.analytics().logEvent('therapist_audio_played', {
                    for_therapist: props.therapistSlug,
                });
            }
        } else {
            console.log('pause');
            actionOnEnd();
            // actionOnPause();
        }

        myAudioTag.current.addEventListener('ended', (event) => {
            actionOnEnd();
        });
    }

    return (
        <div className="Avatar__wrapper" ref={myAvatarWrapper}>
            <div className={mod_class}>
                <div className="Avatar--image_wrapper">
                    {props.text && props.text}
                    {props.icon ? (
                        <div className="Avatar--icon">{props.icon}</div>
                    ) : props.mediaUrl ? <img
                        src={props.mediaUrl}
                        alt="Aepsy avatar"
                        className="Avatar--image"
                    /> : (
                        <ResponsiveSharpImg
                            sharpImage={props.urlSharp}
                            fallbackUrl={props.src}
                            className="Avatar--image"
                            altText={props.alt}
                            type={
                                props.urlSharp
                                    ? ImageRenderType.Img
                                    : ImageRenderType.BgImage
                            }
                        />
                    )}
                </div>

                {/* Only if audioTrack */}
                {props.audioSrc ? (
                    <>
                        <AvatarAudio state={!gbContext.audioState} />
                        <AvatarAudioButton
                            onchange={(state) => handleAudioButtonChange(state)}
                            buttonState={buttonState}
                        />

                        <audio ref={myAudioTag}>
                            <source src={props.audioSrc} type="audio/mpeg" />
                        </audio>
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}
