import { navigate } from 'gatsby';
import Link from '@components/Link';
import React from 'react';

import Button from '@atoms/Button/Button';
import Avatar from '@components/Avatar/Avatar';
import './CardAction.scss';
import ResponsiveSharpImg from '@atoms/ResponsiveSharpImg/ResponsiveSharpImg';

// Default
CardAction.defaultProps = {
    ctaText: 'Lesen',
    variant: '', // human, image
    theme: 'provider', // provider, ambassador
};

function CardAction({
    variant,
    theme,
    avatar,
    avatarSharp,
    image,
    imageSharp,
    children,
    ctaText,
    ctaLink,
}) {
    const mod_class = [
        'CardAction',
        theme !== undefined ? 'theme-' + theme : '',
        variant !== undefined ? 'variant-' + variant : '',
    ].join(' ');
    return (
        <div className={mod_class} onClick={() => navigate(ctaLink)}>
            <div className="CardAction--header">
                {image ? (
                    <ResponsiveSharpImg
                        className="CardAction--image"
                        sharpImage={imageSharp}
                        image={image}
                        fallbackUrl={image}
                    />
                ) : null}
            </div>

            <div className="CardAction--content">
                {avatar ? (
                    <div className="CardAction--avatar">
                        <Avatar src={avatar} urlSharp={avatarSharp} size="s" align="left" />
                    </div>
                ) : (
                    ' '
                )}

                <div className="CardAction--textWrapper">{children}</div>
                <div className="CardAction--footer">
                    <Link to={ctaLink}>
                        <Button variant="inline" label={ctaText} />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CardAction;
